.input {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  p {
    font-size: 14px;
    margin: 0;
  }

  a {
    font-size: 12px;
    color: var(--c-gray50);
    margin-top: 4px;
  }

  > div {
    margin-top: 12px;
  }

  input {
    height: 34px;
  }

  .error {
    color: var(--c-red);
    font-size: 14px;
    padding-top: 4px;
  }
}
