.control {
  border-radius: 4px;
  border: 1px solid var(--c-gray20);
  color: var(--c-gray100);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  padding: 14px 16px 14px 12px;
}

