.wrapper {
  table {
    border-spacing: 0;
    width: 100%;

    thead {
      background-color: var(--c-gray5);

      th {
        font-size: 12px;
        font-weight: 400;
        padding: 10px 24px 10px 24px;
      }
    }

    tbody {
      background-color: var(--c-white);

      tr {
        td {
          font-size: 14px;
          font-weight: 400;
          padding: 0 1px 0 1px;

          .vertical_spacing {
            height: 4px;
          }

          .horizontal_spacing {
            width: 32px;
          }

          .order_item {
            padding: 12px 24px 12px 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .order_item_title {
            font-size: 12px;
          }

          @mixin price {
            padding-right: 24px;
          }

          .item_pos_id {
            @include price();
          }

          .order_price {
            @include price();
          }

          .order_price_total {
            @include price();
            font-weight: 700;
          }

          .order_option {
            display: flex;
            flex-direction: row;
            align-items: center;

            padding: 0 0 0 56px;

            @mixin option_item_base {
              display: flex;
              flex-direction: column;
              border: solid;
              border-width: 0 0 0 3px;
              border-color: var(--c-gray5);
              padding: 0 0 0 8px;

              .content {
                padding: 8px 0 8px 0;

                .category {
                  color: var(--c-gray50);
                  font-size: 11px;
                }

                .quantity {
                  margin-right: 4px;
                  color: var(--c-red);
                  font-size: 12px;
                  font-weight: 500;
                }

                .title {
                  font-size: 12px;
                  font-weight: 500;
                }
              }
            }

            .option_item {
              @include option_item_base();
            }

            .child_option_item {
              @include option_item_base();
              padding: 0 0 0 14px;
            }

            .last_option_item {
              @include option_item_base();
              padding: 0 0 8px 8px;
            }

            .last_child_option_item {
              @include option_item_base();
              padding: 0 0 8px 14px;
            }
          }

          @mixin count_base {
            display: flex;
            width: 20px;
            height: 20px;
            font-size: 12px;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            color: var(--c-white);
            margin-right: 12px;
          }

          .count_red {
            @include count_base();
            background: var(--c-red);
          }

          .count_black {
            @include count_base();
            background: var(--c-gray100);
          }
        }
      }
    }
  }
}
