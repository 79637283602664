.button {
  display: block;
  width: 132px;
  background: transparent;
  border: 1px solid var(--c-gray10);
  border-radius: 4px;
  color: var(--c-gray100);
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 8px 16px;
}