.button {
  border-radius: 4px;
  background-color: var(--c-brand);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 104px;

  p {
    color: var(--c-white);
    font-weight: 400;
    padding-left: 8px;
    padding-right: 8px;
  }
}