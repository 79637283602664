.button {
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--c-gray10);
  color: var(--c-gray100);
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  width: 128px;

  &:hover {
    cursor: pointer;
  }
}
