.input {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  p {
    font-size: 14px;
    margin: 0;
  }

  a {
    font-size: 12px;
    color: var(--c-gray50);
    margin-top: 4px;
  }

  input {
    margin-top: 12px;
    height: 44px;
    font-size: 14px;
    padding-left: 12px;
    border: 1px solid var(--c-gray20);
    border-radius: 4px;
  }

  .error {
    color: var(--c-red);
    font-size: 14px;
    padding-top: 4px;
  }
}
.readonly {
  background: var(--c-yellow-alpha);
}
