.itemSection {
  display: flex;
  margin: 0;
  margin-top: 24px;
  flex-direction: column;
  border: 1px solid var(--c-gray10);
  border-bottom: none;

  .itemSectionTitle {
    height: 55px;
    margin: 0;
    padding: 0 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 56px;
    background-color: var(--c-gray5);
    border-bottom: 1px solid var(--c-gray10);
  }

  .categoryTitle {
    overflow: hidden;
    height: 71px;
    margin: 0;
    padding: 0 28px;
    font-size: 14px;
    font-weight: 700;
    line-height: 72px;
    border-bottom: 1px solid var(--c-gray10);
  }

  .items {
    margin: 0;
  }

  .item {
    display: flex;
    height: 71px;
    padding: 0 28px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-gray10);
  }

  .itemTitle {
    overflow: hidden;
    margin: 0;
    flex: 1;
    font-size: 14px;
    line-height: 72px;
  }

  .itemPrice {
    width: 144px;
    margin: 0;
    font-size: 16px;
    line-height: 66px;
    text-align: end;
  }

  .ellipsis {
    display: inline-block;
    max-width: calc(100% - 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
