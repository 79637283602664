.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 14px 24px;

    .category {
      align-items: center;
      color: var(--c-gray100);
      display: flex;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      margin-bottom: 2px;
    }

    .title {
      align-items: center;
      color: var(--c-gray100);
      display: flex;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .search {
      align-items: flex-start;
      background: var(--c-white);
      border: 1px solid var(--c-gray20);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      max-height: 36px;
      width: 926px;

      .searchIcon {
        height: 16px;
        margin: 8px 10px;
        position: absolute;
        width: 16px;
      }

      .searchInput {
        border: 0px solid var(--c-gray20);
        border-radius: 4px;
        font-size: 14px;
        height: 30px;
        padding: 0 36px;
        width: 100%;
        &::placeholder {
          color: var(--c-gray20);
          font-weight: 400;
        }
      }
    }
  }
  .body {
    border-top: 2px solid var(--c-gray10);
    border-bottom: 2px solid var(--c-gray10);
    height: 614px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 100%;

    .description {
      color: var(--c-gray50);
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
    }

    &::-webkit-scrollbar {
      display:none;
    }

    table {
      border-spacing: 0;
      thead {
        div {
          color: var(--c-gray50);
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          padding: 8px 0 0 16px;
        }
      }
      tr {
        cursor: pointer;

        &:hover {
          background-color: var(--c-gray5);
          border-color: var(--c-gray5);
          color: #333;
        }

        td {
          border-bottom: 1px solid var(--c-gray10);
          display: flex;
          flex-direction: row;
          max-height: 37px;
          max-width: 972px;
        }

        .checkBox {
          align-items: center;
          border: none;
          padding: 10px 12px 6px 16px;

          .before {
            background-color: var(--c-white);
            border: 2px solid var(--c-gray10);
            border-radius: 20px;
            box-sizing: border-box;
            content: '';
            display: inline-block;
            height: 20px;
            position: relative;
            visibility: visible;
            width: 20px;
          }

          .after {
            border-radius: 10px;
            background-color: var(--c-gray100);
            background-image: url(/icon/check.svg);
            background-position: center;
            background-repeat: no-repeat;
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            position: relative;
          }
        }

        .item {
          align-items: center;
          display: flex;
          flex-direction: row;
          padding: 0px 13px;
          min-width: 576px;

          .badgeWrapper {
            margin-left: 8px;
          }

          .name {
            align-items: center;
            color: var(--c-gray100);
            display: flex;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            margin-left: 6px;
          }
        }

        @mixin text {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }

        .posId {
          @include text();
          align-items: center;
          display: flex;
          padding: 10px 12px;
          width: 144px;
        }

        .price {
          @include text();
          align-items: center;
          display: flex;
          padding: 10px 12px;
          width: 88px;
        }

        .badge {
          align-items: flex-end;
          justify-items: end;
          padding: 7px 6px 8px 23px;
          width: 96px;

          .pfStatus {
            background-color: var(--c-gray5);
            border-radius: 10px;
            color: var(--c-gray40);
            font-size: 12px;
            font-weight: 400;
            padding: 2px 6px 4px 8px;
            height: 20px;
            width: 64px;
          }
        }
      }

      .trSelected {
        cursor: pointer;
        background-color: var(--c-accent-primary-light);
        &:hover {
          border-color: var(--c-accent-primary-light);
          background-color: var(--c-accent-primary-light);
          color: #333;
        }
      }
    }
  }

  .footer {
    align-items: center;
    display: flex;
    height: 72px;
    justify-content: center;
    width: 100%;
  }
}