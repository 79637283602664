.wrapper {
  .button {
    display: inline-flex;
    padding: 14px 18px;
    margin-top: 24px;
    cursor: pointer;
    border: 1px solid var(--c-gray10);
    box-sizing: border-box;
    border-radius: 2px;

    img {
      margin-right: 4px;
    }
  }

  .error {
    color: var(--c-red);
    font-size: 14px;
    padding-top: 4px;
  }
}
