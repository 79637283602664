.platforms {
  display: flex;
  padding: 0 24px 24px;
  flex: 1;
  border: 1px solid var(--c-gray10);
  border-bottom: none;

  li {
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    padding: 24px 12px 0 12px;
    border-top: 4px solid transparent;

    .icon {
      width: 36px;
      height: 36px;
    }

    &:not(:last-child) {
      margin-right: 50px;
    }

    .platFormName {
      font-size: 14px;
      margin-top: 12px;
    }

    .badge {
      position: absolute;
      top: 9px;
      left: 50px;
    }

    p {
      margin: 0;
    }
  }
  .selectedPlatform {
    border-top: 4px solid var(--c-blue);
  }

  .emptyPlatform {
    cursor: default;
    opacity: 0.1;
  }

  .count {
    display: flex;
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: var(--c-white);
    background: var(--c-red);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }
}
