.wrapper {
  overflow: hidden;
  display: flex;
  min-height: 100vh;
}

.sidebar {
  position: fixed;
  width: 208px;
  height: 100vh;
  z-index: 999999;
}

.content {
  overflow: hidden;
  flex: 1;
  margin-left: 208px;
}
