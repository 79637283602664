.view {
  width: 100%;
  height: 70px;
  border-radius: 4px;
  border: 1px solid var(--c-gray10);

  .select {
    display: flex;
    padding: 12px;
    height: 70px;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    .selected {
      color: var(--c-gray100);
    }

    .unSelected {
      color: var(--c-blue);
    }
  }

  .dropDownWrapper {
    position: relative;
    z-index: 3;
    height: 416px;
    padding-bottom: 120px;

    .dropDown {
      width: 881px;
      height: 416px;
      position: absolute;
      padding: 12px 0 0 0;
      top: 8px;
      right: 0;
      z-index: 2;
      border-radius: 2px;
      box-sizing: border-box;
      background: var(--c-white);
      box-shadow: 0px 0px 8px 0px #0B081A1F;

      .search {
        width: calc(100% - 24px);
        height: 48px;
        padding-left: 12px;
        margin: 0 12px;
        border: 1px solid var(--c-gray10);
        border-radius: 4px;
        box-sizing: border-box;
        z-index: 99;
      }

      p {
        color: var(--c-gray100);
      }

      ul {
        height: calc(416px - 48px - 12px);
        overflow: scroll;
        overflow-x: unset;

        .optionList {
          box-sizing: border-box;

          .title {
            flex: 1;
            padding-right: 8px;
            box-sizing: border-box;
          }
          .id {
            width: 128px;
            padding-right: 8px;
            box-sizing: border-box;
          }
          .price {
            width: 10%;
            text-align: right;
            box-sizing: border-box;
          }
        }

        li {
          padding-left: 12px;
          height: 86px;
          align-items: center;
          border: none;
          cursor: pointer;

          &:hover {
            background-color: var(--c-gray5);
            color: #333;
          }
        }
      }

    }
  }
}
