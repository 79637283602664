.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  .header {
    display: flex;
    flex-direction: row;
    padding: 24px 0 24px 0;

    .title {
      font-size: 16px;
      font-weight: 700;
      color: var(--c-gray100);
    }
  }

  .body {
    padding: 2px 0 24px 0;

    ul {
      li {
        display: flex;
        flex-direction: row;

        .timeline_icon {
          img {
            height: 100%;
          }
        }

        @mixin timeline_content_base {
          padding-top: 16px;
          padding-bottom: 16px;
          margin-left: 16px;

          p {
            margin: 0;
            padding: 2px 0 2px 0;
            .hour {
              font-size: 16px;
              font-weight: 500;
            }

            .day {
              font-size: 11px;
              font-weight: 500;
              margin-left: 4px;
            }

            .main {
              font-size: 16px;
              font-weight: 500;
              margin-left: 16px;
            }

            .sub {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .timeline_content_black {
          @include timeline_content_base();
          color: var(--c-gray100);
        }

        .timeline_content_red {
          @include timeline_content_base();
          color: var(--c-red);
        }
      }
    }
  }
}