.help_badge {
  background-color: var(--c-light-grayish-red);
  color: var(--c-red);
  font-size: 11px;
  font-weight: 700;
  padding: 4px 8px 4px 4px;
  border-radius: 22px;

  img {
    position: relative;
    top: 3px;
  }

  .text {
    position: relative;
    top: -1px;
    margin-left: 4px;
  }
}