.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c-brand);
  border-radius: 4px;
  color: var(--c-white);
  cursor: pointer;
  font-size: 14px;
  height: 32px;

  img {
    vertical-align: middle;
    padding-right: 5px;
  }
}