.list {
  display: flex;
  flex-wrap: wrap;

  li {
    width: calc(100% / 2 - 1px);
    min-height: 119px;

    a {
      display: flex;
      padding: 24px;
      font-size: 14px;
      color: var(--c-gray100);
      flex-direction: column;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          word-break: break-all;
        }
      }

      .platform {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--c-gray10);
    }
    &:nth-child(2n + 1) {
      border-right: 1px solid var(--c-gray10);
    }
  }
}
