.badge {
  align-items: center;
  color: var(--c-red);
  display: flex;
  font-size: 11px;
  font-weight: 500;
  flex-direction: row;
  justify-content: flex-end;

  img {
    height: 16px;
    width: 16px;
  }
}