.wrapper {
  border: 1px solid var(--c-gray10);
  padding: 16px;

  .platform {
    background: var(--c-gray5);
    border: 1px solid var(--c-gray10);
    padding: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      padding: 6px;
      margin-right: 12px;
      border-radius: 50%;
      background: var(--c-brand);
    }

    p {
      color: var(--c-gray100);
      font-size: 14px;
      margin: 0 40px 0 0;
    }
  }

  .store {
    display: flex;
    align-items: center;
    padding: 8px 16px;

    p {
      color: var(--c-gray100);
      font-size: 14px;
      min-width: 84px;
      margin-right: 8px;
    }
  }

  .brand {
    display: flex;
    align-items: center;
    padding: 8px 16px;

    p {
      color: var(--c-gray100);
      font-size: 14px;
      min-width: 84px;
      margin-right: 8px;
    }
  }
}
