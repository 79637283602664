.button {
  color: var(--c-gray100);
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }
}
