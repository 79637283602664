.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .title {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }

  .description {
    margin: 5px 0 0 0;
    font-size: 12px;
    color: var(--c-gray50);
  }

  .fieldset {
    margin-top: 16px;
    padding: 0;
    border: none;

    .radioInput {
      cursor: pointer;
    }

    .radioInput:before {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      position: relative;
      top: -2px;
      left: -1px;
      background-color: var(--c-white);
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid var(--c-gray100);
      box-sizing: border-box;
    }

    .radioInput:checked:after {
      width: 10px;
      height: 10px;
      position: relative;
      top: -25px;
      left: 4px;
      border-radius: 10px;
      background-color: var(--c-gray100);
      display: inline-block;
      content: '';
    }

    .label {
      font-size: 14px;
      font-weight: 400;
      padding-left: 16px;
      margin-right: 24px;
      color: var(--c-gray100);
      cursor: pointer;
    }
  }

  .inputs {
    display: flex;
    flex-direction: row;
    margin-top: 28px;

    .dateInput {
      height: 44px;
      width: 174px;
      margin-right: 12px;
      padding: 0 12px;
      font-size: 14px;
      border: 1px solid var(--c-gray20);
      border-radius: 4px;
    }

    .timeInput {
      height: 44px;
      width: 118px;
      padding: 0 12px;
      font-size: 14px;
      border: 1px solid var(--c-gray20);
      border-radius: 4px;
    }
  }

  .error {
    color: var(--c-red);
    font-size: 14px;
    padding-top: 4px;
  }
}

.readonly {
  background: var(--c-yellow-alpha);
}
