.table {
  width: 100%;
  border-spacing: 0;

  th {
    height: 56px;
    color: var(--c-gray100);
    background-color: var(--c-gray5);
    font-size: 14px;
    font-weight: normal;
    border-bottom: 1px solid var(--c-gray10);
    text-align: left;
    padding: 0 8px;

    &:first-child {
      padding-left: 28px;
    }

    &:last-child {
      padding-right: 28px;
    }
  }

  td {
    height: 85px;
    color: var(--c-gray100);
    font-size: 14px;
    font-weight: medium;
    padding: 0 8px;
    border-bottom: 1px solid var(--c-gray10);

    &:first-child {
      padding-left: 28px;
    }

    &:last-child {
      width: 132px;
      padding-right: 28px;
    }
  }
}

.pagination {
  margin: 32px;
}

.empty {
  padding: 24px;
  font-size: 14px;
  color: var(--c-gray100);
}
