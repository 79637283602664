.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pagination {
  overflow: hidden;

  li {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    float: left;
    margin-left: 8px;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
    }
  }
}

.item {
  font-size: 14px;
  color: #595959;
  cursor: pointer;
}

.active {
  background-color: var(--c-gray100);
  color: var(--c-white);
}

.prev {
  cursor: pointer;

  a::before {
    width: 14px;
    height: 14px;
    content: url('/icon/left.svg');
  }
}

.next {
  cursor: pointer;

  a::before {
    width: 14px;
    height: 14px;
    content: url('/icon/right.svg');
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.text {
  color: var(--c-gray50);
  font-size: 14px;
  margin: 16px 0 0 0;
}
