.header {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 28px 24px;
  background: var(--c-white);
  border-bottom: 1px solid var(--c-gray10);

  ul {
    li {
      float: left;

      &:first-child {
        a {
          margin-left: -8px;
        }
      }

      &:not(:first-child)::before {
        content: '/';
        font-size: 12px;
        color: var(--c-gray50);
      }

      a {
        padding: 0 8px;
        font-size: 12px;
        word-break: break-all;
        color: var(--c-gray50);
      }
    }
  }

  h2 {
    margin: 19px 0 0 0;
    font-size: 16px;
    font-weight: 400;
    word-break: break-all;
    color: var(--c-gray100);

    div {
      display: inline-block;
    }

    a {
      cursor: pointer;
    }

    input {
      display: flex;
      height: 28px;
      min-width: 200px;
      max-width: 1000px;
      font-size: 14px;
      margin-right: 4px;
      padding-left: 12px;
      border: 1px solid var(--c-gray20);
      border-radius: 4px;
    }
  }
}
