.list {
  li {
    a {
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 14px;
      word-break: break-all;
      color: var(--c-gray100);

      &:hover {
        cursor: pointer;
      }

      .countWarning {
        display: flex;
        flex-direction: row;

        .count {
          display: flex;
          width: 24px;
          height: 24px;
          margin-right: 8px;
          font-size: 14px;
          color: var(--c-white);
          background: var(--c-red);
          border-radius: 4px;
          align-items: center;
          justify-content: center;
        }

        img {
          display: flex;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--c-gray10);
    }
  }
}

.emptyView {
  padding: 0 8px 24px;

  .emptyText {
    font-size: 24px;
    margin-bottom: 56px;
  }

  a {
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    color: var(--c-brand);
  }
}
