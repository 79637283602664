.container {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  .stepper {
    margin-right: -74px;
    padding: 3px 0 0;
    position: relative;

    button {
      cursor: default;
    }

    .step {
      z-index: 1;
    }

    .stepActive {
      width: 4px;
      height: 4px;
      background: white;
    }

    & > div {
      width: 198px;

      & > div > div {
        font-size: 12px;
        & > span {
          margin-top: 14px;
        }
      }

      &:first-child {
        & > div > div {
          margin-left: 182px;

          span {
            text-align: left;
          }
        }
      }

      &:last-child {
        & > div > div {
          margin-right: 194px;

          span {
            text-align: right;
          }
        }
      }
    }
  }
}
