.warpper {
  min-width: 80px;
  max-width: 20%;
  margin: 8px;
  padding: 24px;
  background: var(--c-white);
  border: 1px solid var(--c-gray10);

  .title {
    margin: 0 0 16px;
    font-size: 14px;
    color: var(--c-gray100);
  }

  .value {
    margin: 0;
    font-size: 26px;
    color: var(--c-gray100);
  }
}
