.comment {
  background-color: var(--c-gray5);
  border-radius: 4px;
  padding: 8px;
  font-size: 11px;
  font-weight: 400;
  color: var(--c-gray100);

  .icon {
    position: relative;
    top: 2.5px;
    height: 16px;
    width: 16px;

    margin-right: 8px;
  }
}