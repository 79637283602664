.wrapper {
  display: flex;
  padding: 0 12px;

  li {
    margin: 0 12px;
    border-bottom: 4px solid white;

    &:hover {
      cursor: pointer;
    }

    a {
      display: block;
      padding: 16px 4px;
      color: var(--c-gray50);
      font-size: 14px;
    }

    &.selected {
      border-bottom: 4px solid var(--c-blue);

      a {
        color: var(--c-gray100);
      }
    }
  }
}
