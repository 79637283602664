.card {
  background: var(--c-white);
  border: 1px solid var(--c-gray10);
  margin: 24px 24px 48px;
}
.hideCardBorder {
  border: none;
}

.header {
  border-bottom: 1px solid var(--c-gray10);
  padding: 18px 24px 17px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--c-gray100);
  }

  a {
    font-size: 14px;
    text-decoration: underline;
    color: var(--c-brand);
  }
}

.back {
  display: flex;
  align-items: center;
  padding: 16px 0;

  a {
    margin-right: 24px;
  }
}

.search {
  width: 440px;
  height: 32px;
  margin-left: 24px;
  position: relative;

  .searchIcon {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    left: 8px;
  }
}

.searchInput {
  width: 440px;
  height: 32px;
  padding: 0 36px;
  font-size: 14px;
  border: 1px solid var(--c-gray20);
  border-radius: 4px;
  outline-offset: 0;

  &::placeholder {
    color: var(--c-gray20);
  }
}

.dispHeaderBorder {
  border: 1px solid var(--c-gray10);
}
