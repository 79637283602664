.header {
  ul {
    padding: 16px 20px;
    background-color: var(--c-gray20);
  }

  li {
    font-size: 14px;
    font-weight: '400';
    color: var(--c-gray100);
    display: inline-block;
    vertical-align: middle;
  }
}

.list {
  ul {
    padding: 16px 20px;
    background-color: var(--c-white);

    &:hover {
      cursor: pointer;
      background-color: var(--c-gray5);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--c-gray10);
    }
  }

  li {
    display: inline-block;
    vertical-align: middle;

    a {
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 14px;
      color: var(--c-gray100);
    }
  }
}

.col_fulfillment {
  width: 10%;
  margin-right: 56px;
  justify-content: 'center';
}

.col_status {
  width: 10%;
  margin-right: 56px;
  justify-content: 'center';
}

.col_platform {
  width: 10%;
  padding-left: 9px;
  margin-right: 56px;
  justify-content: 'center';
}

.col_order_number {
  width: 10%;
  margin-right: 56px;
  justify-content: 'center';
}

.col_order_name {
  width: 10%;
  margin-right: 56px;
  justify-content: 'center';
}

.col_brand {
  width: 10%;
  margin-right: 56px;
  justify-content: 'center';
}

.col_total {
  width: 10%;
  justify-content: 'center';
}

.fulfillment {
  font-size: 16px;
  color: var(--c-gray100);
}

.status {
  font-size: 16px;
  color: var(--c-gray100);
}

.status__cancel {
  @extend .status;
  color: var(--c-red);
}

.time {
  font-size: 14px;
  color: var(--c-gray100);
}

.icon {
  width: 40px;
  height: 40px;
}

.id {
  font-size: 16px;
  color: var(--c-gray100);
}

.name {
  font-size: 16px;
  color: var(--c-gray100);
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.brand {
  display: flex;
  align-items: center;
}

.brand_name {
  width: 161px;
  font-size: 12px;
}
