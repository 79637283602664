.warpper {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray100);
  }

  .fieldset {
    border: none;
    margin: 16px 0;
    padding: 0;

    .input {
      cursor: pointer;
    }

    .input:before {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      position: relative;
      top: -2px;
      left: -1px;
      background-color: var(--c-white);
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid var(--c-gray100);
      box-sizing: border-box;
    }

    .input:checked:after {
      width: 10px;
      height: 10px;
      position: relative;
      top: -25px;
      left: 4px;
      border-radius: 10px;
      background-color: var(--c-gray100);
      display: inline-block;
      content: '';
    }

    .label {
      font-size: 14px;
      font-weight: 400;
      padding-left: 16px;
      margin-right: 24px;
      color: var(--c-gray100);
      cursor: pointer;
    }
  }

  .error {
    color: var(--c-red);
    font-size: 14px;
    padding-top: 4px;
  }
}

