@mixin primaryButtonBase {
  background: var(--c-brand);
  border: transparent;
  border-radius: 4px;
  color: var(--c-white);
  display: block;

  &:hover {
    cursor: pointer;
  }
}

.small {
  @include primaryButtonBase();
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  width: 128px;
}

.medium {
  @include primaryButtonBase();
  font-size: 18px;
  font-weight: 400;
  height: 56px;
  width: 296px;
}

.large {
  @include primaryButtonBase();
  font-size: 16px;
  font-weight: 500;
  height: 56px;
  width: 468px;
}

.disabled {
  background: var(--c-gray20);
  pointer-events: none;
}
