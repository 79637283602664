.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: var(--c-dark);

  .logo {
    width: 140px;
    height: 22px;
    margin: 22px 4px;
  }
}

.topMenu {
  margin-top: 20px;
  margin-bottom: auto;
}

.bottomMenu {
  margin-bottom: 48px;
}

.menu {
  height: 40px;
  margin-bottom: 8px;

  a {
    display: block;
    color: var(--c-gray40);
    font-size: 14px;
  }
}

.selectedMenu {
  height: 40px;
  margin-bottom: 8px;
  background: var(--c-gray80);

  a {
    display: block;
    color: var(--c-white);
    font-size: 14px;
  }
}

.menu:hover {
  background: var(--c-gray80);
  cursor: pointer;

  a {
    color: white;
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 12px 22px;

  img {
    width: 14px;
    margin-right: 16px;
  }
}
