.options {
  border: 1px solid var(--c-gray10);
  border-radius: 2px;
  display: inline-flex;

  li {
    float: left;
    padding: 10px 16px;
    font-size: 12px;
    color: var(--c-gray50);
    cursor: pointer;
  }

  .selected {
    background: var(--c-gray100);
    color: var(--c-white);
  }
}
