.table {
  width: 100%;
  margin-bottom: 43px;
  border: 1px solid var(--c-gray10);
  box-sizing: border-box;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--c-gray5);
    padding: 0 16px;

    .masterMenu {
      width: 47%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h3 {
        width: 70%;
        display: flex;
        color: var(--c-gray100);
        font-size: 14px;
        font-weight: 400;
      }
      p {
        width: 30%;
        text-align: right;
        color: var(--c-gray100);
        font-size: 14px;
        font-weight: 400;
      }
      img {
        width: 18px;
        height: 18px;
        margin: 0 36px;
      }
    }

    .menu {
      width: 53%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h3 {
        width: 70%;
        display: flex;
        font-size: 14px;
        font-weight: 400;
      }
      .pos {
        width: 120px;
        color: var(--c-gray100);
        font-size: 14px;
        font-weight: 400;
        text-align: left;
      }

      .price {
        width: 88px;
        color: var(--c-gray100);
        font-size: 14px;
        font-weight: 400;
        text-align: right;
      }

      .priceBox {
        display: flex;
        flex-direction: column;

        .badgeWrapper {
          display: flex;
          flex-direction: row;

          justify-self: flex-end;

        }
      }
    }
  }

  .tableBody {
    display: flex;

    ul {
      width: 100%;

      .menuWarning {
        background-color: var(--c-red-alpha) !important;
      }

      li {
        display: flex;
        box-sizing: border-box;
        border-bottom: 1px solid var(--c-gray10);
        align-items: center;
        width: 100%;
        padding: 0 16px;

        &:last-child {
          border-bottom: none;
        }

        .iconWarning {
          width: 28px;
        }

        .masterMenu {
          width: 47%;
          display: flex;
          align-items: center;

          .itemName {
            width: 80%;
            padding: 8px 0;
            display: flex;
            flex-direction: row;

            p {
              color: var(--c-gray100);
              font-size: 14px;
              font-weight: 400;
              line-height: 1.6em;
            }

            a {
              font-size: 12px;
              padding: 2px 4px;
              background-color: var(--c-gray5);
              border: 1px solid var(--c-gray10);
              border-radius: 2px;
              margin-left: 4px;
            }
          }

          .prices {
            width: 20%;
            margin-right: 90px;

            .price {
              height: 86px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                color: var(--c-gray100);
                font-size: 14px;
                font-weight: 400;
                text-align: right;
              }
            }
          }
        }

        .menus {
          width: 53%;
          padding: 8px 0;

          .menu {
            width: 100%;
            height: 86px;
            display: flex;
            align-items: center;

            .item {
              width: 70%;
              display: flex;
              font-size: 14px;
              font-weight: 400;
              padding-right: 16px;
              box-sizing: border-box;
            }

            .pos {
              width: 120px;
              color: var(--c-gray100);
              font-size: 14px;
              font-weight: 400;
              text-align: left;
            }

            .price {
              width: 88px;
              color: var(--c-gray100);
              font-size: 14px;
              font-weight: 400;
              text-align: right;
            }
          }
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.6em;
    }
  }
}
