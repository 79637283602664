.list {
  li {
    a {
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 14px;
      word-break: break-all;
      color: var(--c-gray100);

      &:hover {
        cursor: pointer;
      }

      .countWarning {
        display: flex;
        flex-direction: row;

        .count {
          display: flex;
          height: 24px;
          margin-right: 8px;
          padding: 0 8px;
          font-size: 14px;
          color: var(--c-white);
          background: var(--c-red);
          border-radius: 4px;
          align-items: center;
          justify-content: center;
        }

        img {
          display: flex;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--c-gray10);
    }
  }
}