.primaryBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
  background: var(--c-brand);
  border-radius: 2px;

  span {
    font-weight: 500;
    font-size: 12px;
    color: var(--c-white);
  }
}

.warningBadge {
  align-items: center;
  background: var(--c-red);
  border-radius: 2px;
  color: var(--c-white);
  display: block;
  font-weight: 500;
  font-size: 12px;
  flex-direction: row;
  text-align: center;
  padding: 1px 0px 4px 0px;

  height: 12px;
  width: 32px;

}

.whiteBadge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 10px;

  background: var(--c-white);
  border-radius: 2px;

  span {
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;

    color: var(--c-brand);
  }
}