.wrapper {
  height: 100%;
  margin: 0 12px;
  padding: 20px 0;
  background: var(--c-white);
  border: 1px solid var(--c-gray10);
  box-sizing: border-box;

  .header {
    padding: 0 24px 20px;
    margin: 0;
    border-bottom: 1px solid var(--c-gray10);

    p {
      margin: 0;
      font-size: 16px;
      color: var(--c-gray100);
    }
  }

  .content {
    padding: 24px;

    .labels {
      display: flex;
      padding: 0 8px 16px;
      border-bottom: 2px solid var(--c-gray10);

      p {
        margin: 0;
        color: var(--c-gray50);
        font-size: 14px;

        &:nth-child(2) {
          margin-left: 12px;
          margin-right: auto;
        }
      }
    }

    .ranking {
      height: 380px;
      overflow-y: scroll;

      li {
        display: flex;
        align-items: center;
        padding: 12px 8px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--c-gray10);
        }

        p {
          margin: 0;
          color: var(--c-gray100);
          font-size: 14px;
        }

        .rank {
          width: 32px;
        }

        .category {
          flex: 1;
          margin: 0 8px;

          .category_subtitle {
            margin-top: 4px;
            color: var(--c-gray50);
            font-size: 10px;
          }
        }

        .sales {
          margin-left: auto;
        }
      }
    }
  }
}
